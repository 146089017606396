<template>
  <div class="wrapper-content">
    <!-- 培训查询 -->

    <el-form :inline="true" :model="queryParams" ref="queryParams" label-width="50px" class="search-bar">
      <el-form-item label="课程:" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入培训课程名称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="search">搜索</el-button>
        <el-button @click="resetForm('queryParams')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 培训筛选 -->
    <div class="screening-bar">
      <ul>
        <li>
          <span class="screening-title">课程分类：</span>
          <div class="screening-list">
            <span class="screening-type" @click="selectClick(1, '')" :class="{ active: queryParams.childCategoryId == '' }">全部</span>
            <span class="screening-span" v-for="(item, index) in classifyList" :key="index" @click="selectClick(1, item.id)"
                  :class="{ active: item.id == queryParams.childCategoryId }">{{ item.categoryName }}</span>
          </div>
          <a href="javascript:;" @click="moreItem" class="screening-more">更多<i class="el-icon-arrow-down"/></a>
        </li>
        <li>
          <span class="screening-title">付费类型：</span>
          <div class="screening-list">
            <span class="screening-type" @click="selectClick(2, '')" :class="{ active: queryParams.collectFee === '' }">全部</span>
            <span class="screening-span" v-for="(item, index) in payList" :key="index" @click="selectClick(2, item.value)"
                  :class="{ active: item.value === queryParams.collectFee }">{{ item.name }}</span>
          </div>
        </li>
        <li>
          <span class="screening-title">授课方式：</span>
          <div class="screening-list">
            <span class="screening-type" @click="selectClick(3, '')" :class="{ active: queryParams.model === '' }">全部</span>
            <span class="screening-span" v-for="(item, index) in teachingList" :key="index" @click="selectClick(3, item.value)"
                  :class="{ active: item.value === queryParams.model }">{{ item.name }}</span>
          </div>
        </li>
        <li>
            <span class="screening-title">报名时间：</span>
          <div class="screening-list ">
            <div class="time-wrapper">
              <el-date-picker size="small" @change="getApplyTime('apply')" v-model="applyTime" type="daterange"
                              range-separator="至" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
          </div>

        </li>
        <li>
          <span class="screening-title">授课时间：</span>
          <div class="screening-list ">
            <div class="time-wrapper">
              <el-date-picker size="small" @change="getApplyTime('course')" v-model="courseTime" type="daterange"
                              range-separator="至" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
          </div>

        </li>
      </ul>
    </div>
    <!-- 活动list -->
    <div class="in_course">
      <div class="in_course-item" v-for="item in courseList" :key="item.id" @click="goDetail(item.id)">
        <el-image class="in_course-img" :src="loadUrl(item.coverImg)" fit="cover"></el-image>
        <div class="in_course-text">
          <h3>
            <el-tag v-if="item.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
            <el-tag v-if="item.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
            <el-tag v-if="item.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
            <el-tag v-if="item.status == 9" effect="dark" size="mini">授课中</el-tag>
            <el-tag v-if="item.status == 10" type="info" effect="dark" size="mini">授课已结束</el-tag>
            {{ item.name }}
          </h3>
          <span class="date-text">报名时间：{{ formatDateH(item.signStartTime) }} 至{{ formatDateH(item.signEndTime) }}</span>
          <span class="date-text">授课地址：{{item.province}}{{item.city}}{{item.area}}{{item.address}}</span>
        </div>
      </div>
    </div>
    <!-- page -->
    <el-pagination v-if="total > 0" class="page-bar" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   :current-page="queryParams.currentPage" :page-size="queryParams.pageSize" layout="total,  prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
</template>

<script>
import {courseCourseCategoryOps, courseCourseInfoPage} from "../../api/course";
export default {
  components: {},
  data() {
    return {
      courseList: [],
      total: 0,
      queryParams: {
        currentPage: 1,
        pageSize: 8,
        name: '',
        // categoryId: '',
        collectFee:'',//0.免费 1.收费
        model:'',//授课方式（1-线上视频，2-线上资料，3-线下）
        signStartTime:'',//报名开始时间
        signEndTime:'',//报名截止时间
        startTime:'',//课程开始时间
        endTime:'',//课程结束时间
        status: "5,7,8,9,10",
        childCategoryId:''
      },
      dates: [
        {name: '课程分类', type: '全部', list: [], isShow: true},
        {name: '付费类型', type: '全部', list: [{id:1,title:'免费',value:0},{id:1,title:'付费',value:1}], isShow: false},
        {name: '授课方式', type: '全部', list: [{id:1,title:'线上视频',value:1},{id:1,title:'线上资料',value:2},{id:1,title:'线下',value:3}], isShow: false}
      ],
      isMore: false,
      categoryList: [],
      level: 0,
      selectedCategory: {},
      selectedCategory2: {},
      selectedCategory3: {},

      classifyList:[],
      payList:[
        {id:1,name:'免费',value:0},
        {id:2,name:'付费',value:1}
      ],
      teachingList:[
        {id:1,name:'线上视频',value:1},
        {id:2,name:'线上资料',value:2},
        {id:3,name:'线下',value:3}
      ],
      applyTime: [],
      courseTime: [],
    }
  },
  mounted() {
    this.initData()
    this.getCourseCourseCategoryOps()
  },
  methods: {
    //列表
    initData(){
      this.$store.commit("showLoading");
      courseCourseInfoPage(this.queryParams).then(res=>{
        if (res.code == 200) {
          this.courseList = res.data.list;
          this.total = res.data.pagination.total;
        } else {
          this.$message({ message: res.msg, duration: 1500 });
        }
        this.$store.commit("hideLoading");
      }).catch(()=>{
        this.$message({ message: '请求失败，请刷新页面！', duration: 1500 });
        this.$store.commit("hideLoading");
      })
    },
    //课程类目
    getCourseCourseCategoryOps(){
      courseCourseCategoryOps().then(res=>{
        if(res.code == 200){
          this.classifyList = res.data.list
        }
      })
    },
    //帅选点击
    selectClick(num,value){
      console.log(value)
      switch (num) {
        case 1:
          // this.queryParams.categoryId = value;
          this.queryParams.childCategoryId = value
          break;
        case 2:
          this.queryParams.collectFee = value;
          break;
        case 3:
          this.queryParams.model = value;
          break;
      }
      this.$nextTick(() => {
        this.initData();
      });
    },
    search() {
      this.initData()
    },
    resetForm() {
      this.queryParams = {
        currentPage: 1,
        pageSize: 8,
        name: '',
        // categoryId: '',
        collectFee:'',//0.免费 1.收费
        model:'',//授课方式（1-线上视频，2-线上资料，3-线下）
        signStartTime:'',//报名开始时间
        signEndTime:'',//报名截止时间
        startTime:'',//课程开始时间
        endTime:'',//课程结束时间
        status: "5,7,8,9,10",
        childCategoryId:''
      }
      this.applyTime=[]
      this.courseTime=[]
      this.initData()
    },

    moreItem(el) {
      let _el = el.currentTarget
      let prev = el.currentTarget.previousElementSibling
      _el.innerHTML = ''

      this.isMore = !this.isMore
      if (!this.isMore) {
        _el.innerHTML = '更多<i class="el-icon-arrow-down" />'
        prev.style.height = '40px'
      } else {
        _el.innerHTML = '收起<i class="el-icon-arrow-up" />'
        prev.style.height = 'auto'
      }
    },
    //处理时间搜索
    getApplyTime(type) {
      if (type === 'apply') {
        if(this.applyTime){
          this.queryParams.signStartTime = this.applyTime[0]
          this.queryParams.signEndTime = this.applyTime[1]
        }else {
          this.applyTime=[]
          this.queryParams.signStartTime=''
          this.queryParams.signEndTime=''
        }
        this.initData()
      } else {
        if(this.courseTime){
          this.queryParams.startTime = this.courseTime[0]
          this.queryParams.endTime = this.courseTime[1]
        }else {
          this.courseTime=[]
          this.queryParams.startTime=''
          this.queryParams.endTime=''
        }
        this.initData()
      }
    },
    //分页
    handleSizeChange(val) {
      this.queryParams.pageSize = val
      this.initData()
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val
      this.initData()
    },
    //跳转
    goDetail(id){
      this.$router.push(`course-detail/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.screening-bar {
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: 1200px;
  box-sizing: border-box;

  .el-breadcrumb__item {
    cursor: pointer;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: start;
    // padding: 10px 0;
    font-size: 14px;
    line-height: 20px;
    color: #555;
  }

  .screening-title {
    margin: 10px 5px;
    font-size: 14px;
    color: #555;
    flex-shrink: 0;
  }
}

.screening-type,
.screening-list .screening-span {
  display: inline-block;
  cursor: pointer;
  margin: 10px 5px;
  padding: 0 10px;
  color: #555;
  border-radius: 3px;
}

.screening-type,
.screening-more {
  flex-shrink: 0;
  height: 20px;
}

.screening-list {
  flex-grow: 1;
  height: 40px;
  overflow: hidden;
}

.screening-more {
  margin-top: 10px;
  color: #999;
}

.screening-bar li .screening-type:hover,
.screening-bar li .screening-type.active,
.screening-bar li .screening-span:hover,
.screening-bar li .screening-span.active {
  background-color: #1e50ae;
  color: #fff;
}

.time-wrapper {
  display: flex;
  align-items: center;
  height: 40px
}

.breadcrumb {
  padding: 0 5px 15px 5px;
  border-bottom: 1px solid #eeeeee;
}
</style>


